<template>
    <div class="flex-grow custom_tabs">
        <template v-if="isMobile">
            <template v-if="isConsolidator">
                <a-tabs default-active-key="consolidations" class="custom_tabs">
                    <a-tab-pane key="consolidations" tab="Консолидации">
                        <List
                            addButton
                            :createConsolidation="createConsolidation"
                            :model="model"
                            :name="page_name"
                            :page_name="page_name" />
                    </a-tab-pane>
                    <a-tab-pane key="templates" tab="Шаблоны">
                        <Cards
                            isScheduled
                            :model="model"
                            :name="templates_page_name"
                            :params="params"
                            :page_name="templates_page_name" />
                    </a-tab-pane>
                </a-tabs>
            </template>
            <template v-else>
                <List
                    showPageTitle
                    :model="model"
                    name="consolidations_table"
                    :page_name="page_name" />
            </template>
        </template>
        <template v-else>
            <template v-if="isConsolidator">
                <a-tabs default-active-key="consolidations" class="custom_tabs">
                    <a-tab-pane key="consolidations" tab="Консолидации">
                        <ModuleWrapper :pageTitle="pageTitle">
                            <template v-slot:h_left>
                                <PageFilter
                                    :model="model"
                                    :key="page_name"
                                    class="mr-2"
                                    size="large"
                                    :page_name="page_name" />
                            </template>
                            <template v-slot:h_right>
                                <a-button
                                    v-if="isConsolidator"
                                    type="primary" 
                                    icon="plus"
                                    class="mr-2"
                                    size="large"
                                    @click="createConsolidation()">
                                    Новая консолидация
                                </a-button>
                                <SettingsButton
                                    :pageName="page_name"
                                    class="ml-2" />
                            </template>
                            <Table 
                                :model="model"
                                tableType="consolidation"
                                :page_name="page_name" />
                        </ModuleWrapper>
                    </a-tab-pane>
                    <a-tab-pane key="templates" tab="Шаблоны">
                        <ModuleWrapper pageTitle="Шаблоны консолидаций">
                            <template v-slot:h_left>
                                <PageFilter
                                    :model="model"
                                    :key="templates_page_name"
                                    class="mr-2"
                                    size="large"
                                    :page_name="templates_page_name" />
                            </template>
                            <Cards
                                :model="model"
                                name="templates_consolidations_table"
                                :params="params"
                                :page_name="templates_page_name"
                                :isScheduled="true" />
                        </ModuleWrapper>
                    </a-tab-pane>
                </a-tabs>
            </template>
            <template v-else>
                <ModuleWrapper :pageTitle="pageTitle">
                    <template v-slot:h_left>
                        <PageFilter
                            :model="model"
                            :key="page_name"
                            class="mr-2"
                            size="large"
                            :page_name="page_name" />
                    </template>
                    <template v-slot:h_right>
                        <a-button
                            v-if="isConsolidator"
                            type="primary" 
                            icon="plus"
                            class="mr-2"
                            size="large"
                            @click="createConsolidation()">
                            Новая консолидация
                        </a-button>
                        <SettingsButton
                            :pageName="page_name"
                            class="ml-2" />
                    </template>
                    <Table 
                        :model="model"
                        tableType="consolidation"
                        :page_name="page_name" />
                </ModuleWrapper>
            </template>
        </template>
        <CreateConsolidation
            :pageName="page_name" />
    </div>
</template>

<script>
import Cards from './components/Cards.vue'
import CreateConsolidation from './components/CreateConsolidation'
import List from './components/List.vue'
import ModuleWrapper from '@/components/ModuleWrapper/index.vue'
import PageFilter from '@/components/PageFilter'
import SettingsButton from '@/components/TableWidgets/SettingsButton'
import Table from './components/Table'
import eventBus from '@/utils/eventBus'
export default {
    name: 'ConsolidationIndex',
    components: {
        Cards,
        CreateConsolidation,
        List,
        ModuleWrapper,
        PageFilter,
        SettingsButton,
        Table
    },
    data() {
        return {
            page_name: 'consolidations_table',
            templates_page_name: 'templates_consolidations_table',
            model: 'consolidation.ConsolidationModel',
            isConsolidator: false,
            params: {
                is_scheduled: true
            }
        }
    },
    mounted() {
        this.getIsConsolidator()
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobile
        },
        pageTitle() {
            return this.$route?.meta?.title || ''
        },
    },
    methods: {
        createConsolidation() {
            eventBus.$emit('create_consolidation')
        },
        async getIsConsolidator() {
            try {
                const { data } = await this.$http.get(`/consolidation/get_org_administrators`)
                this.isConsolidator = data.length ? true : false
            } catch(e) {
                console.log(e)
            }
        }
    }
}
</script>

<style scoped lang="scss">
.custom_tabs::v-deep {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .ant-tabs-content, 
    .ant-tabs-tabpane-active {
        display: flex;
        flex-grow: 1;
    }
}
</style>